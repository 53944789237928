import { useEffect, useState } from "react";
import { BiArrowToTop } from 'react-icons/bi';

// -------------------------------[ SCROLL TO TOP BUTTON ]------------------------------- //

export default function ScrollToButtonButton() {

    const [hasScrollToTopButton, setHasScrollToTopButton] = useState(false);
    
    function backTop() {
        document.body.scrollIntoView()
    }
    
    function toggleScrollTop() {
        setHasScrollToTopButton(
            document.body.scrollTop > 50 || document.documentElement.scrollTop > 50
        )
    }

    useEffect(() => {
        window.addEventListener('scroll', toggleScrollTop);

        return () => {
            window.removeEventListener('scroll', toggleScrollTop);

        }
    }, [])

    return (
        <>
            {hasScrollToTopButton && (
                <button className="scroll-top btn btn-secondary shadow" onClick={() => backTop()}>
                    <BiArrowToTop size={27}/>
                </button>
            )}
        </>
    )
}
