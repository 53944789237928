import './App.css';
import ScrollToButtonButton from "./components/ui-components/scroll-to-top-button";
import WspButton from "./components/ui-components/wsp-button";
import Header from "./components/header-section/header";
import Footer from "./components/footer-section/footer-section";
import { Hero, Feactures, Contact } from './components/landing-page-sections/landing-page-section';
import { MdOutlineCopyright } from 'react-icons/md';

function App() {
  return (
    <div className="App">
      <Header />
      <WspButton />
      <ScrollToButtonButton />
      <div data-bs-spy="scroll" data-bs-target="#navbar" data-bs-root-margin="0px 0px -30%" data-bs-smooth-scroll="true" className="scrollspy-example bg-body-tertiary " tabindex="0">
        <section id="scrollspyHeading1">
          <Hero />
        </section>
        <section id="scrollspyHeading2">
          <Feactures />
        </section>
        <section id="scrollspyHeading3">
          <Contact />
          <Footer />
          <p style={{ margin: 0, padding: 10 }}>
            Copyright <MdOutlineCopyright /> |
            Sitio desarrollado por <a href="https://www.linkedin.com/in/gabrielpalocz" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>Gabriel Palocz</a> |
            Imagen de <a href="https://www.freepik.es/vector-gratis/ilustracion-concepto-abogada_40467234.htm#query=abogada&position=7&from_view=search&track=robertav1_2_sidr">storyset</a> en Freepik
          </p>
        </section>
      </div>
    </div>
  );
}

export default App;
