import React from 'react'


/**
 * 
 * @returns The loader for when the page is loading
 */

const Loader = () => (

    <div className="container">
        <div className="row align-items-center" style={{ height: 650 }}>
            <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status" style={{color:'#ea7a69'}}/>
            </div>
        </div>
    </div>
    
);

export default Loader;
