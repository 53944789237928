import { FaWhatsapp } from 'react-icons/fa';

// -------------------------------[ WHATSAPP BUTTON ]------------------------------- //

//To define if it is a desktop or a mobile device
var userAgent = function userAgent() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(navigator.userAgent)) {
        return "mobile";
    }
    return "desktop";
};
var isMobile = false; //Default isMobile attributes

export default function WspButton() {

//open a whatsapp number function
function openWhatsApp({ tel, msg }) {

    //To omit any zeroes, brackets, or dashes when adding the phone number in international format
    var notNumber = new RegExp("[^0-9/]", "gi");
    var leadingZeros = new RegExp("^0+", "gi");

    //Add true to the variable if mobile
    isMobile = userAgent() === 'mobile';

    //WhatsApp click to chat does not work with non number digits and leading 0s: https://faq.whatsapp.com/general/chats/how-to-use-click-to-chat/
    var whatappTel = tel.replace(notNumber, "").replace(leadingZeros, "");

    //WhatsApp url
    var clickToChatUrl = "https://wa.me/" + encodeURIComponent(whatappTel);

    //The message if it is provided
    if (msg) {
        clickToChatUrl = clickToChatUrl.concat("?text=" + encodeURIComponent(msg));
    }

    //Open the url 
    if (isMobile) {
        window.location.href = clickToChatUrl; //Directly on Mobile
    } else {
        window.open(clickToChatUrl, "_blank", "noopener, noreferrer"); //New tab on Desktop
    }

};

    return (
        <>
            <button className="wsp-but btn shadow" onClick={() => openWhatsApp({ tel: "+56951683841", msg: "Hola, necesito asesoría legal. ¿Podrías ayudarme? " })}>
                <FaWhatsapp size={27} />
            </button>
        </>
    )
}
